import * as React from "react"
import { Link } from "gatsby"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import Footer from '../components/footer';
import styled from "styled-components"

// markup
const NotFoundPage = () => {
  return (
    <div>
      <HeaderNav>
        <Nav>
          <span className="theme-switch">
            <ThemeToggler>
              {({ theme, toggleTheme }) => (
                <label className={"theme-switcher switcher-" + theme}>
                  <input
                    type="checkbox"
                    className="theme-checkbox"
                    onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                    checked={theme === 'dark'}
                  />{' '}
                  Dark mode
                </label>
              )}
            </ThemeToggler>
          </span>
        </Nav>
      </HeaderNav>
      <Wrapper>
        <h1>Oops, that page isn’t here.</h1>
        <p>
            Maybe this link is broken. Maybe something was deleted, or moved. In any case, there’s nothing to see here...
          <br />
          <Link to="/work">View projects</Link>.
        </p>
      </Wrapper>
      <Footer />
    </div>
  )
}

const Wrapper = styled.div`
  max-width: var(--maxWidth-4xl);
  margin: 6rem auto;
  padding: 0 20px;
`

const Nav = styled.nav`
  display: inline-block;
  padding: 0 2px 2px 10px;
  border-radius: 28px;
  font-size: 18px;
  line-height: 36px;
  vertical-align: middle;

  @media (min-width: 1800px) {
    margin-right: 0;
  }

  @media (min-width: 668px) {
    margin-right: 30px;
  }

  @media (max-width: 480px) {
    padding-left: 0;
  }
`

const HeaderNav = styled.div`
  padding: 10px 20px;
  text-align: right;

  @media (max-width: 480px) {
    padding: 10px;
  }

  @media (min-width: 1800px) {
    padding: 20px 0;
  }
`

export default NotFoundPage
